import { throwError as _throw } from 'rxjs';
//src/app/features/images/services/imageSave.service.ts

import { Injectable, Inject, EventEmitter } from '@angular/core';
import { map, catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

import { IAWSSaveResponse, UploadInput } from '@sybl/feature-images-models';

interface PostParts {
  dataType: string;
  dataPosition: number;
  data: any; //base64
  imageUUID?: string;
}

@Injectable()
export class MailerService {
  data;
  responseData: any;
  uploadInput: EventEmitter<UploadInput>;
  googleUrl: string;
  private dataApiServer: string;

  constructor(@Inject('appUrls') appUrls: any, private http: HttpClient) {
    this.dataApiServer = appUrls.API_HTTPS_SERVER;
    // this.initializeDataService();
    this.http = http;
    this.uploadInput = new EventEmitter<UploadInput>();
  }

  testEmail(form?: any) {
    const googleUrl = this.dataApiServer + '/api-server/mail-api/test-mail';

    const senderName = form.senderName;
    const body = form.body;
    const ccAddresses = form.ccAddresses;
    const emailAddresses = form.emailAddresses;
    const replyToAddresses = form.replyToAddresses;
    const toAddresses = form.toAddresses;
    const senderSource = form.senderSource;
    const templateName = form.templateName;
    const templateData = form.templateData;
    const sendToCategories = form.sendToCategories;

    //const channelId = youTubeApiRequest.channelId;

    const options = {
      params: new HttpParams()
        .set('senderName', senderName)
        .set('body', body)
        .set('ccAddresses', ccAddresses)
        .set('emailAddresses', emailAddresses)
        .set('replyToAddresses', replyToAddresses)
        .set('toAddresses', toAddresses)
        .set('senderSource', senderSource)
        .set('templateName', templateName)
        .set('templateData', templateData)
        .set('sendToCategories', sendToCategories),
      /* more CC email addresses */
    };

    return this.http
      .get(googleUrl, options)
      .pipe(
        map((res: any) => {
          //IHttpResponse
          const resBody = res; //JSON.parse(res._body);
          const payload: IAWSSaveResponse = resBody.payload;
          if (resBody.msg === 'GOOGLE_API_SUCCESS') {
            return resBody;
          } else {
            // Need to do something with image not saved error.
          }
        }),
        catchError((err) => {
          console.warn('Error GOOGLE_API', err);
          return err;
        })
      )
      .subscribe((d) => {
        //
        return;
      });
  }

  createEmailTemplate(form) {
    const apiUrl = this.dataApiServer + '/api-server/mail-api/create-template';

    return this.http.post(apiUrl, form, { withCredentials: true }).pipe(
      tap((response: any) => {
        // If did not respond with a user return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        }

        if (response.uuid !== undefined) {
          const saveDetails = {
            status: 'success',
            data: response,
          };
          return {}; //this.documentsFacade.openSaveDialog(saveDetails)
        }
        return {};
      }),
      catchError((err) => {
        console.warn('Error on Company', err);
        return err;
      })
    );
  }

  sendEmailTemplate(form: any) {
    const googleUrl =
      this.dataApiServer + '/api-server/mail-api/send-template-email';
    const senderName = form.senderName;
    const ccAddresses = form.ccAddresses;
    const emailAddresses = form.emailAddresses;
    const replyToAddresses = form.replyToAddresses;
    const toAddresses = form.toAddresses;
    const senderSource = form.senderSource;
    const templateName = form.templateName;
    const templateData = form.templateData;
    const sendToCategories = form.sendToCategories;

    //const channelId = youTubeApiRequest.channelId;

    const options = {
      params: new HttpParams()
        .set('senderName', senderName)
        .set('ccAddresses', ccAddresses)
        .set('emailAddresses', emailAddresses)
        .set('replyToAddresses', replyToAddresses)
        .set('toAddresses', toAddresses)
        .set('senderSource', senderSource)
        .set('templateName', templateName)
        .set('templateData', templateData)
        .set('sendToCategories', sendToCategories),
      /* more CC email addresses */
    };

    return this.http.get(googleUrl, options).pipe(
      map((res: any) => {
        //IHttpResponse
        const resBody = res; //JSON.parse(res._body);
        const payload: IAWSSaveResponse = resBody.payload;
        if (resBody) {
          return resBody;
        } else {
          // Need to do something with image not saved error.
        }
      }),
      catchError((err) => {
        console.warn('Error GOOGLE_API', err);
        return err;
      })
    );
  }

  saveMailingList(form) {
    const apiUrl =
      this.dataApiServer + '/api-server/mail-api/save-mailing-list';

    return this.http.post(apiUrl, form, { withCredentials: true }).pipe(
      tap((response: any) => {
        // If did not respond with a user return an error.
        if (response.error != null) {
          const error = {
            authAttempt: response.error.authAttempt,
            wrongPassword: response.error.wrongPassword,
            lockUntil: response.error.lockUntil,
            tries: response.error.tries,
          };

          return { error };
        }

        if (response.uuid !== undefined) {
          const saveDetails = {
            status: 'success',
            data: response,
          };
          return {}; //this.documentsFacade.openSaveDialog(saveDetails)
        }
        return {};
      }),
      catchError((err) => {
        console.warn('Error on Company', err);
        return err;
      })
    );
  }

  sendTestEmail() {
    const apiUrl =
      this.dataApiServer + '/api-server/mail-api/test-single-email';
    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        console.warn('Error GOOGLE_API', err);
        return err;
      })
    );
  }

  listTemplates() {

    const apiUrl = this.dataApiServer + '/api-server/mail-api/list-templates';
    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((err) => {
        console.warn('Error GOOGLE_API', err);
        return err;
      })
    );
  }

  deleteEmailTemplate(templateName) {
    const apiUrl = this.dataApiServer + '/api-server/mail-api/delete-template';

    return this.http.post(apiUrl, templateName, { withCredentials: true }).pipe(
      tap((response: any) => {
        const saveDetails: any = { status: '' };

        if (response.$metadata !== undefined) {
          if (response.$metadata.httpStatusCode === 200) {
            saveDetails.status = 'success';
          }
        }
        return saveDetails;
        // return this.documentsFacade.openSaveDialog(saveDetails)

        //this.documentsFcade.openSaveDialog(saveDetails)
      }),
      catchError((err) => {
        console.warn('Error on Company', err);
        return err;
      })
    );
  }

  getEmailTemplate(templateName) {
    const apiUrl = this.dataApiServer + '/api-server/mail-api/get-template';
    const options = {
      params: new HttpParams().set('templateName', templateName),
    };
    return this.http.get(apiUrl, options).pipe(
      map((res: any) => {
        //IHttpResponse
        const resBody = res; //JSON.parse(res._body);
        if (resBody.$metadata !== undefined) {
          console.error('LOOK ATH THIS----');
        }
        if (resBody.Template !== undefined) {
          return resBody.Template;
        }
      }),
      catchError((err) => {
        console.warn('Error GOOGLE_API', err);
        return err;
      })
    );
  }

  updateEmailTemplate(form) {
    const apiUrl = this.dataApiServer + '/api-server/mail-api/update-template';
    return this.http.post(apiUrl, form, { withCredentials: true }).pipe(
      tap((response: any) => {
        const saveDetails: any = { status: '' };

        if (response.$metadata !== undefined) {
          if (response.$metadata.httpStatusCode === 200) {
            saveDetails.status = 'success';
          }
        }

        return saveDetails; // this.documentsFacade.openSaveDialog(saveDetails)
      }),
      catchError((err) => {
        console.warn('Error on Company', err);
        return err;
      })
    );
  }
}
